// Install fonts
import '@fontsource/reddit-sans/400.css';
import '@fontsource/work-sans/400.css';
import '@fontsource/work-sans/600.css';
import './src/styles/main.scss';

export const onClientEntry = async () => {
    if (typeof IntersectionObserver === `undefined`) {
        await import(`intersection-observer`);
    }
};
